import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

const Layout = () => {

     document.body.style.backgroundColor = "#fff4d9"

    return (
        <>
            <Container>
                <Outlet />
            </Container>
        </>
    );
}

export default Layout;