import React from 'react';

const RedirectHandler = () => {


    try {
        const urlParams = new URLSearchParams(window.location.search);
        const newParams = new URLSearchParams();
        for (const param of urlParams.entries()) {
            newParams.append("_" + param[0], param[1]);
        }

        const redirectUrl = `https://bloops-api.duncanwatts.com/api/CompleteAuthentication?${newParams.toString()}`;

        document.location.href = redirectUrl;
    }
    catch (error) {
        document.location.href = "/";
    }

    return (
        <div />
    );
}

export default RedirectHandler;