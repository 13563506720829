import React from 'react';
import { Segment, Header, Image, Grid, GridColumn } from 'semantic-ui-react';

const Authenticated = () => {


    const urlParams = new URLSearchParams(window.location.search);
    const portNumber = urlParams.get("p");

    fetch(`http://localhost:${portNumber}/Complete`).catch(error => {});

    return (
        <>


            <Grid verticalAlign='middle' padded centered style={{ "height": "100vh", }}>
                <GridColumn width={8}>
                    <Segment padded='very' textAlign='center' style={{ "borderRadius": "2rem", }}>
                        <Image src='/logo.png' size='small' centered />
                        <Header>

                            Authentication Successful
                        </Header>
                        <p>You may now close this window and return to the Bloops application.</p>
                    </Segment>
                </GridColumn>
            </Grid>
        </>
    );
}

export default Authenticated;