import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home'
import Authenticated from './Authenticated'
import RedirectHandler from './RedirectHandler'
import Layout from './Layout'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/Authenticated" element={<Authenticated />} />
          <Route path="/CompleteAuthentication" element={<RedirectHandler />} />
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
