import React from 'react';
import { Segment, Header, Image, Grid, GridColumn, Button } from 'semantic-ui-react';

const Home = () => {
    return (
        <>
            <Grid verticalAlign='middle' padded centered style={{ "height": "100vh", }}>
                <GridColumn width={8}>
                    <Segment padded='very' textAlign='center' style={{ "borderRadius": "2rem", }} as={Button} href='https://bloops.blob.core.windows.net/downloads/Bloops-win-Setup.exe'>
                        <Image src='/logo.png' size='small' centered />
                        <Header>
                            Bloops
                        </Header>
                        <p>Click to download.</p>
                    </Segment>
                </GridColumn>
            </Grid>
        </>
    );
}

export default Home;